export const surveyIN = `
    id
    uniqueUrl
    surveyName
`
export const surveyINT = `
    ${surveyIN}
    surveyType
`
export const surveyINTC = `
    ${surveyINT}
    createdDate
`
export const surveyINJ = `
    ${surveyIN}
    surveyJson
    surveyTheme
`

export const surveyBase = `
    ${surveyINJ}
    createdDate
`
export const idStatus = `
    id
    status
    `
export const surveyINS = `
    ${surveyIN}
    status
    `

export const surveyAnalysis = `
    surveyAnalysis {
        id
        analysisFile
    }
`

export const orderBase = `
    ${idStatus}
    createdDate
`
export const responsesNoAssign = `
    remainingPriceToAssign
    remainingResponsesToAssign
    numberOfResponses
`
export const userInfo = `
    userInfo {
        fullName
        email
        mobileNumber
        keycloakUserId
    }
`
export const userFavorite = `
    userFavorite {
        navBarMode
        favoriteLanguage
        receiveEmail
        newSurveyNotification
        currentSurveyNotification
    }
`

export const surveyAddon = `
    addons {
        custom
        collection
        auditing
    }
`
export const addonsPrice = `
    auditing
    collection
`

export const operatorData = `
    id
    region
    createdDate
    city
    rate
    personalPictureFile
    status
    education
    gender
    iban
    bank
    cvFile
    nationalId
    role
    supervisorId
    experience {
        agency
        startYear
        endYear
    }
    assignedOrders
    accomplishedOrders
    operatorComment {
        id
        comment
        commentorId
    }
    ${userInfo}
`

export const profileOperator = `
    id
    status
    region
    city
    personalPictureFile
    experience {
        agency
        startYear
        endYear
        type
    }
`

export const assignmentBase = `
    id
    status
    quantity
    price
    responses
    numberOfResearcher
`

export const assignmentTargetAudience = `
    targetAudience {
        id
        numberOfResponses
        remainingPriceToAssign
    }
`
export const reviewBase = `
    attachment
 `

export const nameFL = `
    firstName
    lastName
`

export const reviewComment = `
    reviewComment {
        id
        questionName
        questionTitle
        questionComment
        checkSolved
    }
`

export const auditingBase = `
    auditing {
        auditListStatus
        needRejectedData
        auditList
    }
`
export const targetAudiences = `
    targetAudiences {
        id
        male
        female
        minAge
        maxAge
        minIncome
        maxIncome
        maritalStatus
        cities
        regions
        education
        employmentStatus
       ${responsesNoAssign}
    }
`

export const remainingTargetAudiences = `
        id
        male
        female
        minAge
        maxAge
        minIncome
        maxIncome
        maritalStatus
        cities
        regions
        education
        employmentStatus
       ${responsesNoAssign}
`

export const collection = `
    collection {
        collectionType: type
        ${targetAudiences}
    }
`

// targetAudience(id: $id)
export const targetAudienceById = `
    gender {
        min
        max
    }
    age {
        min
        max
    }
    income {
        min
        max
    }
    cities
    regions
    education
    maritalStatus
    employmentStatus
    numberOfResponses
`
export const researchTeamBase = `
    id
    name
    note
`

export const responses = `
        respondent {
            firstName
            lastName
        }
`
export const responsesISISE = `
        id
        status
        startDate
        endDate
        responseSource
`
export const orderResponsesVars = `
    responses {
        ${responsesISISE}
        responseJson
        completionTime
        address {
            countryCode
        }
        location {
            lat
            lng
        }
    }
`
export const surveyVarabled = (body) => `
    id
    survey {
        ${body}
    }
`

export const orderResponsesShared = `
    id
    responseJson
    completionTime
    address {
        countryCode
    }
    status
`

export const orderStatics = `
    orderStatics {
        newOrders
        inprogress
        accomplished
        rejected
    }
`
export const pageInfo = `
    pageInfo {
        hasNext
        currentPage
        totalPages
        totalElements
    }
`

export const dimensionsGql = `
    id
    dimensionName
    questions
    expressions {
        id
        name
        condition
        singleExplain
        groupExplain
        recommendation
    }
`

export const dimensionsFinalDegreeGql = `
    id
    name
    condition
    singleExplain
    groupExplain
    recommendation
`
