import RenderMeta from '@util/RenderMeta'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import LangIcon from 'public/img/lang.svg'
import setLanguage from 'next-translate/setLanguage'
import { buildClass } from '@util/tag'
import ProfileCard from '@components/request-service/profile-card'
import MainLogo from 'public/img/logos/upsilon-logo-become-a-customer.svg'
import { Formik } from 'formik'
import * as Yup from 'yup'
import connectText from '@hooks/connectText'

const BecomeACustomer = () => {
    const { t, lang } = useTranslation()
    const localSwitchTarget = lang === 'ar' ? 'en' : 'ar'
    const cardsStyle = 'bg-grey-600 md:w-[85%] md:p-6 p-5 w-[98%]'
    const connectErrors = connectText('common:researcher.errors')

    const validationSchema = Yup.object().shape({
        estimatedTime: Yup.string().required(t(connectErrors('required'))),
        collectionType: Yup.string().required(t(connectErrors('required'))),
        responsesNo: Yup.number().required(t(connectErrors('required'))),
        fullName: Yup.string().required(t(connectErrors('required'))),
        mobile: Yup.string()
            .matches(/^(5)\d+$/, t(connectErrors('startW5')))
            .matches(/^\d+$/, t(connectErrors('startW5')))
            .min(9, t(connectErrors('Digits9')))
            .max(9, t(connectErrors('Digits9')))
            .required(t(connectErrors('required'))),
        email: Yup.string()
            .email(t(connectErrors('emailInvalid')))
            .required(t(connectErrors('required'))),
        companyName: Yup.string().required(t(connectErrors('required'))),
        message: Yup.string().required(t(connectErrors('required'))),
        attachment: Yup.string().required(t(connectErrors('required')))
    })

    return (
        <>
            <RenderMeta title={t('common:requestService.requestTitle')} />

            <Formik
                initialValues={{
                    fullName: '',
                    email: '',
                    mobile: '',
                    companyName: '',
                    message: '',
                    collectionType: '',
                    responsesNo: 0,
                    estimatedTime: '',
                    totalAmount: 0,
                    attachment: ''
                }}
                validationSchema={validationSchema}
            >
                {({ submitForm }) => {
                    return (
                        <div className='bg-no-repeat bg-cover w-full min-h-screen overflow-auto p-10 m-0 relative'>
                            <div
                                className='absolute top-4 rtl:left-7 ltr:right-7 cursor-pointer flex justify-center items-center gap-1'
                                onClick={() => setLanguage(localSwitchTarget)}
                            >
                                <h3 className='capitalize text-md text-white'>
                                    {t(`common:header.language.${localSwitchTarget}`)}
                                </h3>

                                <LangIcon
                                    className='relative cursor-pointer'
                                    fill='#FFF'
                                />
                            </div>

                            <div className='flex flex-col gap-6 items-center w-full'>
                                <MainLogo className='mt-5 lg:mt-10 mb-3 lg:mb-5' />

                                <div className='-mt-3 mb-4'>
                                    <h1 className='capitalize text-[14px] lg:text-md text-center'>
                                        {t('common:home.requestServiceDescr')}
                                    </h1>
                                </div>

                                <div className={buildClass(cardsStyle, '!p-0')}>
                                    <ProfileCard />
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Formik>
        </>
    )
}

export default BecomeACustomer
